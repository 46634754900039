import { GetServerSideProps } from 'next';
import { IndexPageController } from 'server/controller/page/index/indexPageController';
import type { IGlobalMetaData } from 'types/IGlobalMetaData';
import IndexPage from '../components/PageLevelComponents/ClientIndex';
import { ANONYMOUS_TOKEN_NAME, LOCALE_DEFAULT } from 'config/config';
import { IIndexPageResource } from 'server/resource/page/indexPageResource';
import { Layout } from '@/components/Layout/Layout';

const RootPage = (props: IIndexPageResource) => {
  const {
    metaData,
    data,
    searchItemLabels,
    recipeCopy,
    dynamicPageComponents,
    footerNavData,
    footerImage,
    usercentricsIds,
    locale,
    searchOverlayData,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    difficultyCopy,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    pathTranslations,
    userToken,
  } = props as any; // IIndexPageResource type must match types, that are used in RootLayoutClient etc.

  return (
    <Layout
      themeName={data?.genericPages.at(0)?.theme || ''}
      pathTranslations={pathTranslations}
      locale={locale}
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
      userToken={userToken}
    >
      <IndexPage
        dynamicPageComponents={dynamicPageComponents}
        globalMetadata={metaData as IGlobalMetaData}
        data={data}
        recipeCopy={recipeCopy}
        difficultyCopy={difficultyCopy as Record<string, string>}
        searchItemLabels={searchItemLabels as Record<string, string>}
        searchOverlayData={searchOverlayData}
      />
    </Layout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale,
  defaultLocale,
  resolvedUrl,
  req,
}) => {
  const anonymousToken = req.cookies[ANONYMOUS_TOKEN_NAME];
  return IndexPageController.getCachedIndexPageData({
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    resolvedUrl,
    userToken: anonymousToken ?? '',
  });
};

export default RootPage;
